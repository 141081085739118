import { PaginatedResponseModel } from "../paginated-response.model";

export class InvoiceEmailTemplate {
    id: number;
    createdDate: Date;
    modifiedDate: Date;
    name: string;
    subject: string;
    replyTo: string;
    to: string;
    cc: string;
    bcc: string;
    body: string;
}

export enum DefaultEmailTemplates {
    GSI = 'GSI'
    , ULT = 'ULT'
    , GSM = 'GSM'
    , SCW = 'SCW'
}

export class CreateEmailTemplateResponse extends InvoiceEmailTemplate { }
export class PatchEmailTemplateResponse extends InvoiceEmailTemplate { }
export class GetListEmailTemplatesResponse extends PaginatedResponseModel<InvoiceEmailTemplate> { }
export class DeleteEmailTemplateResponse { }