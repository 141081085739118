import { HttpClient } from "@angular/common/http";
import { Injectable, OnDestroy } from "@angular/core";
import { Observable, Subject } from "rxjs";
import { AppConfigurationService, HttpMethod, IPaginatedRequestModel, NotificationService, InvoiceEmailTemplate, PatchEmailTemplateResponse, GetListEmailTemplatesResponse, CreateEmailTemplateResponse, DeleteEmailTemplateResponse } from "@app/ultisat/models";
import { HttpPaginatedRequestStream, HttpRequestStream, getPaginatedRequestUrl } from "@app/ultisat/utility";


@Injectable()
export class EmailTemplateService implements OnDestroy {
    //TODO:: Field permissions? Symphony implements it here with mixins - is there a better way?
    private m_ngUnsubscribe = new Subject();

    private m_createEmailTemplate = new HttpRequestStream<InvoiceEmailTemplate>(this.m_http, this.m_appConfig, this.m_notificationSvc, this.m_ngUnsubscribe);
    public newEmailTemplate$ = this.m_createEmailTemplate.response$;
    public isCreateCountryLoading$ = this.m_createEmailTemplate.isLoading$;

    private m_getEmailTemplates = new HttpPaginatedRequestStream<InvoiceEmailTemplate>(this.m_http, this.m_appConfig, this.m_notificationSvc, this.m_ngUnsubscribe);
    public emailTemplates$ = this.m_getEmailTemplates.response$;
    public isEmailTemplatesLoading$ = this.m_getEmailTemplates.isLoading$;

    private m_updateEmailTemplate = new HttpRequestStream<InvoiceEmailTemplate>(this.m_http, this.m_appConfig, this.m_notificationSvc, this.m_ngUnsubscribe);
    public updateEmailTemplate$ = this.m_updateEmailTemplate.response$;
    public isUpdateCountryLoading$ = this.m_updateEmailTemplate.isLoading$;

    private m_deleteEmailTemplate = new HttpRequestStream<DeleteEmailTemplateResponse>(this.m_http, this.m_appConfig, this.m_notificationSvc, this.m_ngUnsubscribe);
    public deleteEmailTemplate$ = this.m_deleteEmailTemplate.response$;
    public isDeleteEmailTemplateLoading$ = this.m_deleteEmailTemplate.isLoading$;

    constructor(
        private m_http: HttpClient
        , private m_appConfig: AppConfigurationService
        , private m_notificationSvc: NotificationService
    ) { }

    ngOnDestroy(): void {
        this.m_ngUnsubscribe.next();
        this.m_ngUnsubscribe.complete();
    }

    public createEmailTemplate(emailTemplate: InvoiceEmailTemplate): Observable<CreateEmailTemplateResponse> {
        const url = `${this.m_appConfig.PHARAOH_API_BASE_URL}/InvoiceEmailTemplate/`;
        return this.m_createEmailTemplate.send({ url: url, method: HttpMethod.POST, body: emailTemplate });
    }

    public getEmailTemplates(options?: IPaginatedRequestModel, isPoll = false): Observable<GetListEmailTemplatesResponse> {
        const url = `${this.m_appConfig.PHARAOH_API_BASE_URL}/InvoiceEmailTemplate/${getPaginatedRequestUrl(options)}`;
        return this.m_getEmailTemplates.send({ url: url, isPoll: isPoll });
    }

    public updateEmailTemplate(id: number, obj: any): Observable<PatchEmailTemplateResponse> {
        const url = `${this.m_appConfig.PHARAOH_API_BASE_URL}/InvoiceEmailTemplate/${id}/`;
        return this.m_updateEmailTemplate.send({ url: url, method: HttpMethod.PUT, body: obj });
    }

    public deleteEmailTemplate(id: number): Observable<DeleteEmailTemplateResponse> {
        const url = `${this.m_appConfig.PHARAOH_API_BASE_URL}/InvoiceEmailTemplate/${id}/`;
        return this.m_deleteEmailTemplate.send({ url: url, method: HttpMethod.DELETE });
    }
}
