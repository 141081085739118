/**
 * @module
 * services/api
 * @description
 * Contains necessary services for data transfer to and from the Pharaoh API.
 */

import { AccountService } from './accounts.service';
import { CapabilitiesService } from './capablities.service';
import { ContactService } from './contact.service';
import { CountryService } from './country.service';
import { EmailTemplateService } from './email-template.service';
import { EndOfPeriodService } from './end-of-period.service';
import { InvoiceService } from './invoices.service';
import { JobInfoService } from './job.service';
import { LocationService } from './locations.service';
import { LogService } from './logs.service';
import { NumberService } from './numbers.service';
import { OrderService } from './orders.service';
import { PaymentService } from './payment.service';
import { ProcessedFilesService } from './processed-files.service';
import { ProductClassesService } from './product-classes.service';
import { ProductService } from './products.service';
import { RatePlanService } from './rateplans.service';
import { ReferenceValueService } from './reference-value.service';
import { ReleaseNotesService } from './release-notes.service';
import { StockService } from './stock.service';
import { TierService } from './tiers.service';
import { UsageService } from './usages.service';
import { UserRoleService } from './user-roles.service';
import { UserService } from './users.service';
import { VendorService } from './vendors.service';

export const apiServices = [
    AccountService,
    ContactService,
    CountryService,
    EmailTemplateService,
    EndOfPeriodService,
    InvoiceService,
    JobInfoService,
    LocationService,
    LogService,
    NumberService,
    OrderService,
    PaymentService,
    ProcessedFilesService,
    ProductClassesService,
    ProductService,
    RatePlanService,
    ReferenceValueService,
    StockService,
    TierService,
    UsageService,
    UserRoleService,
    UserService,    
    VendorService,
];

/* 
Only for Root Services used by AppComponent or 
other shared/main module components.
*/
export const apiServicesForRoot = [
    CapabilitiesService,
    ReleaseNotesService,
];

export * from './accounts.service';
export * from './capablities.service';
export * from './contact.service';
export * from './country.service';
export * from './email-template.service';
export * from './end-of-period.service';
export * from './invoices.service';
export * from './job.service';
export * from './locations.service';
export * from './logs.service';
export * from './numbers.service';
export * from './orders.service';
export * from './payment.service';
export * from './processed-files.service';
export * from './product-classes.service';
export * from './products.service';
export * from './rateplans.service';
export * from './reference-value.service';
export * from './release-notes.service';
export * from './stock.service';
export * from './tiers.service';
export * from './usages.service';
export * from './user-roles.service';
export * from './users.service';
export * from './vendors.service';