import { HttpClient, HttpHeaders, HttpResponse } from "@angular/common/http";
import { Injectable, OnDestroy } from "@angular/core";
import { Observable, Subject } from "rxjs";
import { AppConfigurationService, ConfirmEndOfPeriod, EndOfPeriodVerification, GetListEndOfPeriodVerifications, HttpMethod, IPaginatedRequestModel, NotificationService, VerifyEndOfPeriod } from "@app/ultisat/models";
import { HttpPaginatedRequestStream, HttpRequestStream, getPaginatedRequestUrl } from "@app/ultisat/utility";


@Injectable()
export class EndOfPeriodService implements OnDestroy {
    //TODO:: Field permissions? Symphony implements it here with mixins - is there a better way?
    private m_ngUnsubscribe = new Subject();

    private m_verifyEndOfPeriod = new HttpRequestStream<VerifyEndOfPeriod>(this.m_http, this.m_appConfig, this.m_notificationSvc, this.m_ngUnsubscribe);
    public verify$ = this.m_verifyEndOfPeriod.response$;
    public isVerifyEndOfPeriodLoading$ = this.m_verifyEndOfPeriod.isLoading$;

    private m_confirmEndOfPeriod = new HttpRequestStream<ConfirmEndOfPeriod>(this.m_http, this.m_appConfig, this.m_notificationSvc, this.m_ngUnsubscribe);
    public confirm$ = this.m_confirmEndOfPeriod.response$;
    public isConfirmEndOfPeriodLoading$ = this.m_confirmEndOfPeriod.isLoading$;

    private m_eopVerifications = new HttpPaginatedRequestStream<EndOfPeriodVerification>(this.m_http, this.m_appConfig, this.m_notificationSvc, this.m_ngUnsubscribe);
    public eopVerifications$ = this.m_eopVerifications.response$;
    public isEOPVerificationsLoading$ = this.m_eopVerifications.isLoading$;

    private m_endOfPeriodVerification = new HttpRequestStream<EndOfPeriodVerification>(this.m_http, this.m_appConfig, this.m_notificationSvc, this.m_ngUnsubscribe);
    public endOfPeriodVerification$ = this.m_endOfPeriodVerification.response$;
    public isEndOfPeriodVerificationLoading$ = this.m_endOfPeriodVerification.isLoading$;

    constructor(
        private m_http: HttpClient
        , private m_appConfig: AppConfigurationService
        , private m_notificationSvc: NotificationService
    ) { }

    ngOnDestroy(): void {
        this.m_ngUnsubscribe.next();
        this.m_ngUnsubscribe.complete();
    }

    public verifyEndOfPeriod(period: string, isPoll = false): Observable<VerifyEndOfPeriod> {
        const url = `${this.m_appConfig.PHARAOH_API_BASE_URL}/EndOfPeriod/Verify/${period}/`;
        return this.m_verifyEndOfPeriod.send({ url: url, isPoll: isPoll });
    }

    public confirmEndOfPeriod(period: string, isPoll = false): Observable<ConfirmEndOfPeriod> {
        const url = `${this.m_appConfig.PHARAOH_API_BASE_URL}/EndOfPeriod/Confirm/${period}/`;
        return this.m_confirmEndOfPeriod.send({ url: url, isPoll: isPoll, method: HttpMethod.POST });
    }

    public getEopVerifications(options?: IPaginatedRequestModel, isPoll = false): Observable<GetListEndOfPeriodVerifications> {
        const url = `${this.m_appConfig.PHARAOH_API_BASE_URL}/EndOfPeriod/${getPaginatedRequestUrl(options)}`;
        return this.m_eopVerifications.send({ url: url, isPoll: isPoll });
    }

    public getEndOfPeriodVerification(id: number, isPoll = false): Observable<HttpResponse<Blob>> {
        const url = `${this.m_appConfig.PHARAOH_API_BASE_URL}/EndOfPeriod/${id}/`;
        return this.m_http.get(url, { responseType: 'blob', observe: 'response' });
    }
}
