import { PaginatedResponseModel } from "../paginated-response.model";
import { Account } from "./account.model";
import { NumberModel, NumberState } from "./number.model";
import { Product, ProductAvailabilityCost } from "./product.model";
import { RatePlanModel } from "./rateplan.model";
import { StockModel } from "./stock.model";
import { UsageType } from "./usage.model";
import { VendorModel } from "./vendor.model";

export enum InvoiceChargeType {
    UNKNOWN = "UNKNOWN",
    FIXED = "FIXED",
    USAGE = "USAGE",
    INVALID = "INVALID"
}

export class InvoiceCharge {
    id: number;;
    createdDate: Date;
    invoiceId: number;
    accountId: number;
    numberId?: number;
    number?: NumberModel;
    numberStateId?: number;
    numberState?: NumberState;
    ratePlanId?: number;
    ratePlan?: RatePlanModel;
    vendorId?: number;
    vendor?: VendorModel;
    stockId?: number;
    stock?: StockModel;
    product?: Product;
    productId?: number;
    productAvailabilityCost?: ProductAvailabilityCost;
    productAvailabilityCostId?: number;
    type: InvoiceChargeType;
    subType: UsageType;
    price: number;
    discount: number;
    total: number;
    daysBilled: number;
    serviceStart: Date;
    serviceEnd: Date;
}

export class InvoiceModel {
    id: number;
    createdDate: Date;
    modifiedDate: Date;
    dueDate: Date;
    chargePeriod: string;
    total: number;
    balance: number;
    accountId: number;
    account: Account;
    pdfData: string;
    pdfFileName: string;

    payments: Array<any>;
    charges: Array<InvoiceCharge>;
}

export class InvoicePdfModel {
    pdfData: string;
    pdfFileName: string;
}

export enum InvoiceTypes {
    Default
    , GSI
    , ULT
}

export class GetListInvoicesResponse extends PaginatedResponseModel<InvoiceModel> { }
export class GetListInvoicesByAccountResponse extends PaginatedResponseModel<InvoiceModel> { }
export class GetInvoiceResponse extends InvoiceModel { }
export class CreateInvoiceResponse extends InvoiceModel { }
export class PatchInvoiceResponse extends InvoiceModel { }
export class EmailInvoicesResponse { }
export class DeleteInvoiceResponse { } 