export enum CapabilityPharaoh {
    ACCOUNT_CREATE = 'op_account_create'
    , ACCOUNT_READ = 'op_account_read'
    , ACCOUNT_UPDATE = 'op_account_update'
    , ACCOUNT_DELETE = 'op_account_delete'

    , CONTACT_CREATE = 'op_contact_create'
    , CONTACT_READ = 'op_contact_read'
    , CONTACT_UPDATE = 'op_contact_update'
    , CONTACT_DELETE = 'op_contact_delete'

    , COUNTRY_CREATE = 'op_country_create'
    , COUNTRY_READ = 'op_country_read'
    , COUNTRY_UPDATE = 'op_country_update'
    , COUNTRY_DELETE = 'op_country_delete'

    , EMAILTEMPLATE_CREATE = 'op_emailtemplate_create'
    , EMAILTEMPLATE_READ = 'op_emailtemplate_read'
    , EMAILTEMPLATE_UPDATE = 'op_emailtemplate_update'
    , EMAILTEMPLATE_DELETE = 'op_emailtemplate_delete'

    , EOP_VERIFY = 'op_eop_verify'
    , EOP_CONFIRM = 'op_eop_confirm'

    , IMPORT_RAVENDB = 'op_import_ravendb'

    , INVOICE_READ = 'op_invoice_read'

    , JOBINFO_READ = 'op_jobinfo_read'

    , LOCATION_CREATE = 'op_location_create'
    , LOCATION_READ = 'op_location_read'
    , LOCATION_UPDATE = 'op_location_update'
    , LOCATION_DELETE = 'op_location_delete'

    , LOG_READ = 'op_log_read'

    , NUMBER_CREATE = 'op_number_create'
    , NUMBER_READ = 'op_number_read'
    , NUMBER_UPDATE = 'op_number_update'
    , NUMBER_DELETE = 'op_number_delete'

    , ORDER_CREATE = 'op_order_create'
    , ORDER_READ = 'op_order_read'
    , ORDER_UPDATE = 'op_order_update'
    , ORDER_DELETE = 'op_order_delete'

    , PROCESSEDFILES_READ = 'op_processedfiles_read'

    , PRODUCT_CREATE = 'op_product_create'
    , PRODUCT_READ = 'op_product_read'
    , PRODUCT_UPDATE = 'op_product_update'
    , PRODUCT_DELETE = 'op_product_delete'

    , PRODUCTCLASS_CREATE = 'op_productclass_create'
    , PRODUCTCLASS_READ = 'op_productclass_read'
    , PRODUCTCLASS_UPDATE = 'op_productclass_update'
    , PRODUCTCLASS_DELETE = 'op_productclass_delete'

    , RATEPLAN_CREATE = 'op_rateplan_create'
    , RATEPLAN_READ = 'op_rateplan_read'
    , RATEPLAN_UPDATE = 'op_rateplan_update'
    , RATEPLAN_DELETE = 'op_rateplan_delete'

    , STOCK_CREATE = 'op_stock_create'
    , STOCK_READ = 'op_stock_read'
    , STOCK_UPDATE = 'op_stock_update'
    , STOCK_DELETE = 'op_stock_delete'

    , TIER_CREATE = 'op_tier_create'
    , TIER_READ = 'op_tier_read'
    , TIER_UPDATE = 'op_tier_update'
    , TIER_DELETE = 'op_tier_delete'

    , USER_CREATE = 'op_user_create'
    , USER_READ = 'op_user_read'
    , USER_UPDATE = 'op_user_update'
    , USER_DELETE = 'op_user_delete'

    , USERROLE_CREATE = 'op_userrole_create'
    , USERROLE_READ = 'op_userrole_read'
    , USERROLE_UPDATE = 'op_userrole_update'
    , USERROLE_DELETE = 'op_userrole_delete'

    , VENDOR_CREATE = 'op_vendor_create'
    , VENDOR_READ = 'op_vendor_read'
    , VENDOR_UPDATE = 'op_vendor_update'
    , VENDOR_DELETE = 'op_vendor_delete'
}