import { PaginatedResponseModel } from "../paginated-response.model";
import { InvoiceModel } from "./invoice.model";
import { JobInfo } from "./jobinfo.model";
import { UserModel } from "./user.model";

export class VerifyEndOfPeriod extends JobInfo {    
}

export class ConfirmEndOfPeriod extends JobInfo {
}

export class EndOfPeriodVerification {
    id: number;
    createdDate: Date;
    modifiedDate: Date;
    chargePeriod: string;
    fileName: string;
    initiatorId: number;
    initiator: UserModel;
}

export class GetListEndOfPeriodVerifications extends PaginatedResponseModel<EndOfPeriodVerification> {}